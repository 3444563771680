body {
  margin: 0;
  font-family: 'Calibre', 'SFMono', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(17, 24, 39);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
  /* color: white; */
}

:root {
  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-sm: 90%;
}

@font-face {
  font-family: Calibre;
  src: local('Calibre'), url(./fonts/Calibre/Calibre-Regular.ttf);
}

@font-face {
  font-family: SFMono;
  src: local('SFMono'), url(./fonts/SFMono/SFMono-Regular.ttf);
}

.container {
  width: var(--container-width-lg);
  margin: 0 auto;
}

.big-heading {
  font-size: clamp(40px, 8vw, 70px);
  margin: 0;
}

section {
  margin: 0 auto;
  padding: 100px 0;
  max-width: 1000px;
  @media (max-width: 768px) {
    padding: 80px 0;
  }
  @media (max-width: 480px) {
    padding: 60px 0;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 10px 0;
  font-weight: 600;
  color: var(--lightest-slate);
  line-height: 1.1;
}

p {
  margin: 0 0 15px 0;
}

p:last-child,
p:last-of-type {
  margin: 0;
}

a {
  display: inline-block;
  text-decoration: none;
  text-decoration-skip-ink: auto;
  color: inherit;
  position: relative;
  transition: var(--transition);
}
a:hover,
a:focus {
  color: #00ABE1;
}

body {
  margin: 0;
  width: 100%;
  min-height: 100%;
  overflow-x: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: #8892b0;
  font-size: 20px;
  line-height: 1.3;
  @media (max-width: 480px) {
    font-size: 18px;
  }
}

.hidden {
    overflow: hidden;
}

body.blur {
  overflow: hidden;
} 

#content > * {
    filter: blur(5px) brightness(0.7);
    /* transition: var(--transition); */
    pointer-events: none;
    user-select: none;
}

#logo {
  color: #64ffda;
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms var(--easing);
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms var(--easing);
}

.fadedown-enter {
  opacity: 0.01;
  transform: translateY(-20px);
  transition: opacity 300ms var(--easing), transform 300ms var(--easing);
}
.fadedown-enter-active {
  opacity: 1;
  transform: translateY(0px);
  transition: opacity 300ms var(--easing), transform 300ms var(--easing);
}